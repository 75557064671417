import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions} from '../../actions/action_helper';
import { SERVER_URL } from '../../lib/constants';

const leadsApi = createApi({
  reducerPath: 'leadsApi',
  baseQuery: fetchBaseQuery({ baseUrl: SERVER_URL }),
  endpoints: (builder) => ({
    createLead: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'leads',
          method: 'POST',
          data,
        }),
      }),
    }),
  }),
});

export const {useCreateLeadMutation} = leadsApi;
export default leadsApi;
